import React, { useEffect, useState } from 'react';

import Layout from '../layout/Layout';
import { makeStyles } from '@mui/styles'
import { Box, BoxProps, Button, Container, useMediaQuery, useTheme, Typography, Skeleton } from '@mui/material';
import SvgIcon from '../components/SvgIcon';
import { styled } from '@mui/material/styles';
import homeBanner from '@/assets/images/home_banner1_image@2x.png';
import homeBannerBg from '@/assets/images/home_banner1_bg@2x.png';
import twoCircle from '@/assets/images/two-circle.svg';

import homeBanner2 from '@/assets/images/home_banner2@2x.png';
import homeBanner5 from '@/assets/images/home_banner5@2x.png';
import homeBanner6LeftZh from '@/assets/images/home_banner6_left.svg';
import homeBanner6LeftEn from '@/assets/images/home_banner6_left_en.png';
import homeBanner6LeftEs from '@/assets/images/home_banner6_left_es.png';
import homeBanner6LeftFr from '@/assets/images/home_banner6_left_fr.png';
import homeBanner6LeftId from '@/assets/images/home_banner6_left_id.png';
import homeBanner6LeftKm from '@/assets/images/home_banner6_left_km.png';
import homeBanner6LeftMy from '@/assets/images/home_banner6_left_my.png';
import homeBanner6LeftTh from '@/assets/images/home_banner6_left_th.png';
import homeBanner6LeftVi from '@/assets/images/home_banner6_left_vi.png';


import homeBanner6Right from '@/assets/images/home_banner6_right.svg';
import homeBanner3Arrow from '@/assets/images/home_banner3_arrow.svg';
import AppDownload from '../components/AppDownload';
import { useNavigate } from 'react-router-dom';
import { useGetBannerList, usePostBanner } from '../api';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import eventBus from '../plugins/eventBus';


const setion4List = [
    {
        icon: 'home_part4-4',
        className: 'rounded-br-'
    },
    {
        icon: 'home_part4-3',
        className: 'rounded-bl-'
    },
    {
        icon: 'home_part4-2',
        className: 'rounded-tr-'
    },
    {
        icon: 'home_part4-1',
        className: 'rounded-tl-'
    },
    {
        icon: 'home_part4-5',
        className: 'rounded-br-'
    },
]

const useStyles = makeStyles(({
    sction1Xs: {
        background: 'linear-gradient( 180deg, #F9FAF9 0%, #EFF5F1 100%)',
    },
    sction1Md: {
        background: `url(${homeBannerBg}),linear-gradient( 180deg, #F9FAF9 0%, #EFF5F1 100%) bottom left; `,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
    },
    sction3: {
        backgroundImage: `url(${homeBanner3Arrow})`,
        backgroundSize: 'auto 100%',
    }
}))

function Item(props: BoxProps) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{

                ...sx,
            }}
            {...other}
        />
    );
}
const Index: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);
    const { response: bannerList, loading, fetchBannerList } = useGetBannerList();
    const { fetchPostBanner } = usePostBanner();


    const { t, i18n } = useTranslation();
    const setion3List = [
        {
            text: t('realSceneDialog'),
            subText: t('immersiveLearning'),
            icon: 'home_part3_icon1_default',
            activeIcon: 'home_part3_icon1_active'
        },
        {
            text: t('interactiveExercises'),
            subText: t('funLearning'),
            icon: 'home_part3_icon2_default',
            activeIcon: 'home_part3_icon2_active'
        },
        {
            text: t('scientificStreamlining'),
            subText: t('curriculumSystem'),
            icon: 'home_part3_icon3_default',
            activeIcon: 'home_part3_icon3_active'
        },
        {
            text: t('multiLanguage'),
            subText: t('customCourses'),
            icon: 'home_part3_icon4_default',
            activeIcon: 'home_part3_icon4_active'
        },
        {
            text: t('aiIntelligence'),
            subText: t('evaluationSystem'),
            icon: 'home_part3_icon5_default',
            activeIcon: 'home_part3_icon5_active'
        }
    ];

    const homeBanner6LeftList = {
        zh: homeBanner6LeftZh,
        en: homeBanner6LeftEn,
        es: homeBanner6LeftEs,
        fr: homeBanner6LeftFr,
        id: homeBanner6LeftId,
        km: homeBanner6LeftKm,
        my: homeBanner6LeftMy,
        th: homeBanner6LeftTh,
        vi: homeBanner6LeftVi
    }

    const homeBanner6Left = homeBanner6LeftList[i18n.language as keyof typeof homeBanner6LeftList || 'en'] || homeBanner6LeftEn;


    const setionContent = () => {
        return (
            <Box sx={{
                textAlign: {
                    xs: 'center',
                    md: 'right'
                },
            }}>
                <div className='font-size-5 font-600 mb-3'>
                    {t('stoChineseSupports')}
                </div>
                <div className='font-size-14px text-gray-400'>
                    中文 English Français España ဗာရမ် Malay IndonesiaName ภาษาไทย Tiếng Việt ភាសាខ្មែរ
                </div>
            </Box>
        );
    };

    // 使用 useEffect 确保 fetchData 只在组件挂载时调用
    useEffect(() => {

        fetchBannerList();

        const handleLanguageChange = (data: string) => {
            fetchBannerList();
        };

        // 组件挂载时，监听自定义事件
        eventBus.on('languageChange', handleLanguageChange);

        // 组件卸载时，移除监听器
        return () => {
            eventBus.off('languageChange', handleLanguageChange);
        };
    }, []);
    const getClassName = (item: { icon?: string; className: any; }) => {
        return item.className + (isMd ? 1 : 2)
    }

    // 轮播图统计
    function onSwiper() {
        if (bannerList && bannerList.length > 0) {
            fetchPostBanner({ bannerId: bannerList[0].id, isShow: 1 })
        }
    }
    function onSlideChange(item: any) {
        fetchPostBanner({ bannerId: bannerList[item.activeIndex].id, isShow: 1 })
    }
    const navigate = useNavigate();

    const pages = {
        1: '/',
        2: '/guide',
        3: '/study',
        4: '/course',
        5: '/about',
        6: '/customer',
    }
    function bannerClick(item: any) {
        fetchPostBanner({ bannerId: item.id, isClick: 1 })
        switch (item.skipType) {
            case 1:
                const path = pages[item.skipPath as keyof typeof pages];
                item.skipPath && navigate(path);
                break;
            case 2:
                item.skipPath && navigate('/guide/detail/' + item.skipPath);
                break;
            case 3:
                open(item.skipPath);
                break;

        }
    }

    return (
        <Layout>
            <section>
                {
                    loading ?
                        <React.Fragment>
                            <Skeleton variant="rectangular" animation="wave" width={'100%'} height={436} />
                            <Box sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', marginTop: -5, left: 0, right: 0 }}>
                                <Skeleton variant="circular" sx={{ bgcolor: 'grey.900' }} width={10} height={10} />
                                <Skeleton variant="circular" sx={{ bgcolor: 'grey.900', mx: 1 }} width={10} height={10} />
                                <Skeleton variant="circular" sx={{ bgcolor: 'grey.900' }} width={10} height={10} />
                            </Box>
                        </React.Fragment>
                        :
                        <Swiper
                            style={{
                                // @ts-ignore
                                '--swiper-navigation-color': '#50AC70',
                                '--swiper-pagination-color': '#50AC70',
                                alignItems: 'center',
                            }}
                            spaceBetween={50}
                            slidesPerView={1}
                            height={isMd ? null : 436}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true
                            }}
                            lazy="true"
                            onSlideChange={onSlideChange}
                            onSwiper={onSwiper}
                            pagination={{ clickable: true }}
                            modules={[Pagination, Autoplay]}
                        >
                            {bannerList?.map((item: {
                                picPc: string | undefined; pic?: any; id?: any; skipPath?: any
                            }, index: React.Key | null | undefined) => (
                                <SwiperSlide key={index} style={{ textAlign: 'center', height: isMd ? '100%' : 436, backgroundColor: '#F6F6F6' }} >
                                    <img className={'w-full h-full ' + (isMd ? 'object-contain' : 'object-cover')} src={isMd ? item.pic : item.picPc} loading="lazy" onClick={() => { bannerClick(item); }} />
                                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                }

            </section>
            <section>
                <Container>
                    <Box sx={{
                        display: 'flex', alignItems: 'center', flexWrap: {
                            xs: 'wrap',
                            md: 'nowrap'
                        }, textAlign: {
                            xs: 'center',
                            md: 'left'
                        },
                        flexDirection: {
                            xs: 'column-reverse',
                            md: 'row'
                        },
                        marginBottom: {
                            xs: 5,
                            md: 0
                        }
                    }}>
                        <Item className='flex-1' sx={{

                        }}>
                            <div className={isMd ? '' : 'ml-21'}>
                                <img className='mb-3' src={twoCircle} />
                                <div className='mb-3 font-size-10 font-700'>
                                    {t('inHandStart')} <span className='text-primary'>{t('chinese')}</span> {t('inHandEnd')}
                                </div>
                                <p className='font-size-14px mb-6 text-gray-800 text-opacity-60'>
                                    {t('appDescription')}
                                </p>
                                <AppDownload />
                            </div>

                        </Item>
                        <Item className='flex-1'>
                            <img width={isMd ? '100%' : 504} src={homeBanner2} />
                        </Item>
                    </Box>
                </Container>
            </section>
            <section>
                <Container sx={{ padding: { xs: 0, md: 2 } }}>
                    <div className={classes.sction3 + ' py-10  bg-primary bg-opacity-70 text-white  text-center' + (isMd ? '' : ' rounded-8')}>
                        <div className='font-700 font-size-8 mb-6'>
                            {t('improveExperienceStart')} <span className='text-yellow'>{t('chinese')}</span> {t('improveExperienceEnd')}
                        </div>
                        <p className='font-size-14px px-14 mb-10 text-op-60 text-white'>
                            {t('improveDescription')}
                        </p>
                        <div className={(isMd ? 'gap-2' : 'px-14 gap-10') + ' flex justify-center flex-wrap'}>
                            {setion3List.map((item, index) => {
                                return <Item key={index} className='rounded-4' sx={{
                                    '&:hover': {
                                        bgcolor: 'rgba(255,255,255,0.3)',
                                    },
                                    p: {
                                        xs: 1.5,
                                        md: 3
                                    }
                                }}
                                    onMouseEnter={() => setHoverIndex(index)}
                                    onMouseLeave={() => setHoverIndex(null)}
                                >
                                    <SvgIcon className='mb-4' size={isMd ? 60 : 100} name={hoverIndex === index ? item.activeIcon : item.icon} />
                                    <div className='font-700 font-size-14px'>{item.text}<br />{item.subText}</div>
                                </Item>
                            })}
                        </div>
                    </div>
                </Container>
            </section>
            <section className='bg-#ECF3EE'>
                <Container className={isMd ? 'pb-8' : ''}>
                    <Box className='flex' sx={{
                        flexDirection: { xs: 'column-reverse', md: 'row' }, textAlign: { xs: 'center', md: 'right' }, py: {
                            xs: 2.5,
                            md: '86px'
                        },
                    }}>
                        <div className='flex justify-center flex-wrap gap-8 mt-5'>
                            {setion4List.map((item, index) => {
                                return <div key={index} className={(isMd ? 'size-25 rounded-5' : 'size-40 rounded-8') + ' bg-white ' + getClassName(item)}>
                                    <SvgIcon name={item.icon} size={isMd ? 100 : 160} />
                                </div>
                            })}
                        </div>
                        <div className={isMd ? '' : 'ml-7 max-w-500px'} >
                            <img src={twoCircle} />
                            <Box component={'div'} sx={{ fontSize: { xs: 34, md: 40 } }} className='font-size-10 font-700'>
                                <div>{t('learnWithMotherTongueStart')} <span className='text-primary'>{t('chinese')}</span> {t('learnWithMotherTongueEnd')}</div>
                                <div>{t('syncWithLifestyleStart')} <span className='text-primary'>{t('lifestyle')}</span> {t('syncWithLifestyleEnd')}</div>
                            </Box>
                            <Box component={'p'} className='font-size-14px text-gray-800 text-opacity-60' sx={{ mb: { xs: 3, md: 13.5 } }}>
                                {t('customLearningJourney')}
                            </Box>
                            {isMd ? null : setionContent()}

                        </div>
                    </Box>
                    {isMd ? setionContent() : null}
                </Container>
            </section>

            <section>
                <Container className=' py-17'>
                    <Box className='flex justify-center items-center' sx={{ flexDirection: { xs: 'column-reverse', md: 'row', wordBreak: 'break-word' } }}>
                        <div className='flex-1 flex justify-end break-words'>
                            <div className=''>
                                <img src={twoCircle} />
                                <div className='font-size-10 font-700 mb-4'>
                                    <div>{t('scientificSimplifiedStart')}<span className='text-primary'>{t('scientificSimplifiedMid')}</span>{t('scientificSimplifiedEnd')}</div>
                                    {t('learnEasyStart')}<span className='text-primary'>{t('learnEasyMid')}</span>{t('learnEasyEnd')}
                                </div>

                                <div className='flex font-600 items-center mb-2'>
                                    <div className='size-14px bg-primary rounded-full mr-2'></div>
                                    {t('personalizedLearningPath')}
                                </div>
                                <div className='font-size-14px mb-6 text-gray-500'>
                                    {t('personalizedLearningDescription')}
                                </div>

                                <div className='flex font-600 items-center mb-2'>
                                    <div className='size-14px bg-primary rounded-full mr-2'></div>
                                    {t('interactiveTeaching')}
                                </div>
                                <div className='font-size-14px mb-6 text-gray-500'>
                                    {t('interactiveTeachingDescription')}
                                </div>

                                <div className='flex font-600 items-center mb-2'>
                                    <div className='size-14px bg-primary rounded-full mr-2'></div>
                                    {t('fragmentedLearning')}
                                </div>
                                <div className='font-size-14px mb-6 text-gray-500'>
                                    {t('fragmentedLearningDescription')}
                                </div>

                                <div className='flex font-600 items-center mb-2'>
                                    <div className='size-14px bg-primary rounded-full mr-2'></div>
                                    {t('multiDeviceSync')}
                                </div>
                                <div className='font-size-14px mb-6 text-gray-500'>
                                    {t('multiDeviceSyncDescription')}
                                </div>
                            </div>
                        </div>
                        <div className='ml-4 flex-1'>
                            <img src={homeBanner5} width={'100%'} />
                        </div>
                    </Box>
                </Container>
            </section>
            <section>
                <Container>
                    <Box className='flex' sx={{
                        flexDirection: { xs: 'column', md: 'row' }, textAlign: {
                            xs: 'letf',
                            md: 'right'
                        }, py: {
                            xs: 5,
                            md: 7.5
                        }
                    }}>
                        <div className='flex-1'>
                            <img src={homeBanner6Left} width={'100%'} />

                        </div>
                        <Box className='flex-1' sx={{ background: `url(${homeBanner6Right}) no-repeat center right/contain`, wordBreak: 'break-word' }}>
                            <Box className={isMd ? 'px-4' : 'mt-26 ml-7 max-w-420px'} >
                                <img src={twoCircle} />
                                <div className='font-size-10 font-700'>
                                    <div><span className='text-primary'>{t('playUnits')}</span>{t('smallUnits')}</div>
                                    {t('masterChinese')}
                                </div>
                                <div className='text-gray-800 text-opacity-60 font-size-14px font-400 mb-4 line-height-7'>
                                    {t('fromBasicToTraditional')}<br />
                                    <div className=''>{t('languageInteraction')}</div>
                                    {t('shortVideosAndExercises')}
                                    <div className='mt-4'>
                                        {t('HSKContent')}
                                    </div>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </section>
        </Layout >
    );
};

export default Index;