import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import SvgIcon from './SvgIcon';
import { Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import theme from '../theme';
import eventBus from '../plugins/eventBus';


interface LanguageDropdownProps {
    position?: 'top' | 'bottom',
    showIcon?: boolean
}
// 语言列表
const languages = [
    {
        name: 'English',
        value: 'en'
    },
    {
        name: '简体中文',
        value: 'zh'
    },
    {
        name: 'Frangais',
        value: 'fr'
    },
    {
        name: 'Español',
        value: 'es'
    },
    {
        name: 'မြန်မာ',
        value: 'my'
    },
    {
        name: 'Bahasa Indonesia',
        value: 'id'
    },
    {
        name: 'ภาษาไทย',
        value: 'th'
    },
    {
        name: 'Malay',
        value: 'ms'
    },
    {
        name: 'Tiéng Viet',
        value: 'vi'
    },
    {
        name: 'ភាសាខ្មែរ',
        value: 'km'
    },
]

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({ position = 'top', showIcon = true }) => {
    // 切换语言
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        eventBus.emit('languageChange', lng);

    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    return (<div><Button
        aria-controls="language-menu"
        aria-haspopup="true"
        variant={position === 'top' ? 'text' : 'outlined'}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={position === 'top' ? { textTransform: 'none' } : { border: '1px solid white', textTransform: 'none' }}
        className='whitespace-nowrap'
    >
        {showIcon ? position === 'top' ? <span className='size-6'><SvgIcon name="language" /></span> : <SvgIcon name="language-night" size={isMd ? 16 : 24} /> : null}
        <span className={(position === "top" ? "text-secondary" : "text-white") + (isMd ? " font-size-3" : " font-size-4") + ' ml-2 mr-1'}
        >
            {t('language')}
        </span>
        <ArrowDropDownIcon htmlColor={position === 'top' ? '#A8A8A8' : '#FFFFFF'} />
    </Button>
        <Menu
            id="language-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}


            MenuListProps={{
                'aria-labelledby': 'language-button',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {languages.map(item =>
                <MenuItem
                    key={item.name} onClick={() => { changeLanguage(item.value); handleClose(); }}
                    className='text-secondary justify-center!'
                    selected={item.value === i18n.language} // 设置默认选中项
                    sx={{
                        height: 48,
                        ":hover": { color: 'primary.main' },
                        "&.Mui-selected": {
                            backgroundColor: '#EEEFF3',
                            color: 'primary.main',
                            fontWeight: '600'
                        }
                    }
                    }>
                    {item.name}
                </MenuItem>)}
        </Menu>
    </div >
    );
};
export default LanguageDropdown;