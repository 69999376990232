import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Grid, IconButton, Popover, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import Layout from "../layout/Layout"
import { makeStyles } from "@mui/styles"
import circles from '@/assets/images/circles@2x.png'
import aboutPic from '@/assets/images/about-pic@2x.png'
import twoCircle from '@/assets/images/two-circle.svg';
import { useGetAbout } from "../api"
import { useEffect, useRef, useState } from "react"
import React from "react"
import eventBus from "../plugins/eventBus"
import { useTranslation } from "react-i18next"
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(({
    section: {
        background: 'linear-gradient( 180deg, #F9FAF9 0%, #EFF5F1 100%)'
    },
}))


const About: React.FC = () => {

    const { response: content, loading, fetchAbout } = useGetAbout();
    const classes = useStyles()
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const contentRef = useRef<HTMLDivElement>(null); // 类型声明
    const [showButton, setShowButton] = useState(false);


    // 使用 useEffect 确保 fetchData 只在组件挂载时调用
    useEffect(() => {
        fetchAbout().then(() => {
            setTimeout(() => {
                changeContentHeight()
            })
        });;
        const handleLanguageChange = (data: string) => {
            fetchAbout().then(() => {
                setTimeout(() => {
                    changeContentHeight()
                })
            });
        };


        // 组件挂载时，监听自定义事件
        eventBus.on('languageChange', handleLanguageChange);

        // 组件卸载时，移除监听器
        return () => {
            eventBus.off('languageChange', handleLanguageChange);
        };
    }, [contentRef.current]);

    function changeContentHeight() {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            const lineHeight = parseFloat(getComputedStyle(contentRef.current).lineHeight);
            const maxVisibleHeight = lineHeight * 10;// 三行的高度
            setShowButton(contentHeight > maxVisibleHeight);
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        isMd ? setAnchorEl(event.currentTarget) : handleClickOpen()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // dialog

    const [openDialog, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    return (
        <Layout>
            <section className={classes.section}>
                <Container>
                    <Grid container direction={isMd ? "column-reverse" : 'row'} >
                        {/*    */}
                        {/*  */}
                        <Grid item md={6} xs={12} className="flex justify-center items-center" >
                            <Box sx={{
                                maxWidth: {
                                    xs: '100%',
                                    md: 375
                                }
                            }}>
                                {
                                    isMd ? <Box className="flex justify-center items-center" sx={{
                                        height: 61
                                    }}>
                                        <img src={twoCircle} alt="" />
                                        <img className="max-h-61px absolute right-4" src={circles} />
                                    </Box> : null
                                }

                                <Box
                                    sx={
                                        {
                                            fontSize: {
                                                xs: 24,
                                                md: 30
                                            },
                                            textAlign: {
                                                xs: 'center',
                                                md: 'left'
                                            }
                                        }
                                    }
                                    className='font-700 mb-3'>
                                    {t('about')}<span className='text-primary mr-1'>{t('we')}</span>
                                    {isMd ? null : <img className="max-w-214px" src={circles} />}
                                </Box>
                                <Box
                                    ref={contentRef}
                                    sx={{
                                        textAlign: {
                                            xs: 'center',
                                            md: 'left',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showButton ? 10 : 'initial',
                                            WebkitBoxOrient: showButton ? 'vertical' : 'initial',
                                            overflow: showButton ? 'hidden' : 'initial'
                                        }
                                    }
                                    } className="text-gray-800 text-opacity-60 mb-6 font-size-14px whitespace-pre-line relative"

                                >
                                    {loading ? <React.Fragment> <Skeleton animation="wave" />
                                        <Skeleton animation="wave" />
                                        <Skeleton animation="wave" width={'60%'} />
                                        <Skeleton animation="wave" />
                                        <Skeleton animation="wave" />
                                        <Skeleton animation="wave" width={'70%'} />
                                    </React.Fragment>
                                        : <React.Fragment>
                                            <div dangerouslySetInnerHTML={{ __html: content?.aboutUs }}></div>
                                            {showButton && <div className="absolute right-0 bottom-0  bg-#f1f6f3">
                                                <span className="ml-1">...</span>
                                                <span className="text-primary cursor-pointer ml-1 "
                                                    onClick={handleClick}
                                                >{t('viewMore')}</span>
                                            </div>}
                                        </React.Fragment>
                                    }
                                </Box>
                                <Drawer
                                    anchor={'bottom'}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <Typography sx={{ p: 2, maxHeight: '50vh' }}><div dangerouslySetInnerHTML={{ __html: content?.aboutUs }}></div></Typography>
                                </Drawer>

                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box component='img' sx={{
                                maxWidth: {
                                    xs: '100%',
                                    md: 500
                                }
                            }} src={aboutPic} />
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ fontSize: '24px', textAlign: 'center', mt: 2 }}>
                    {t('aboutUs')}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div dangerouslySetInnerHTML={{ __html: content?.aboutUs }}></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button sx={{ fontSize: '18px', height: '57px', minWidth: '200px' }} variant="contained" color="text333" onClick={handleCloseDialog}>{t('iSee')}</Button>
                </DialogActions>
            </Dialog>
        </Layout >
    )
}

export default About