import { AppBar, Box, Container, Drawer, IconButton, Menu, MenuItem, MenuList, Slide, Toolbar, Typography, useScrollTrigger, useTheme } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import LanguageDropdown from '../components/LanguageDropdown';
import SvgIcon from '../components/SvgIcon';
import { ChevronLeft } from '@mui/icons-material';





interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}
function HideOnScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const Header = () => {

    const location = useLocation();

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isGuideDetail = location.pathname.includes('/guide/detail');
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    const { t } = useTranslation();

    // 导航菜单
    const menu = [
        {
            name: t('home'),
            path: '/'
        },
        {
            name: t('learningCheats'),
            path: '/guide'
        },
        {
            name: t('learnforfree'),
            path: '/study'
        },
        {
            name: t('professionalCourses'),
            path: '/course'
        },
        {
            name: t('aboutUs'),
            path: '/about'
        }
    ]
    return <React.Fragment>
        {/* <HideOnScroll> */}
        <AppBar color='transparent' sx={{
            boxShadow: {
                md: 'none',
            },
            backgroundColor: 'white !important',
        }}>
            <Container sx={
                {
                    padding: '0px !important',
                    height: {
                        md: 124
                    },
                    display: {
                        md: 'flex'
                    },
                    alignItems: {
                        md: 'center'
                    },
                }
            }>
                <Toolbar sx={{ flexGrow: { md: 1 } }}>

                    {isGuideDetail && isMd ? <React.Fragment><IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => history.back()}
                    >
                        <ChevronLeft />
                    </IconButton><div className='flex-1'></div> </React.Fragment> :
                        <React.Fragment>
                            <Link to='/' className={(isMd ? 'size-6 font-size-7px' : 'size-21 font-size-6') + ' transition-all bg-primary decoration-none flex items-center justify-center text-white font-700'}>
                                LOGO
                            </Link>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 1, display: { xs: 'block', md: 'none' } }}>
                                STUDY TRUST
                            </Typography>
                        </React.Fragment>}

                    <Typography component="div" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, textAlign: 'center', whiteSpace: 'nowrap' }}>
                        {menu.map(item => <Box
                            sx={{ '&:hover': { color: 'primary.main', }, color: location.pathname === item.path ? 'primary.main' : '#6F6F6F', fontWeight: location.pathname === item.path ? '600' : '' }}
                            component={Link}
                            className={`menu-item ${' mx-8 text-secondary no-underline'}`}
                            key={item.name} to={item.path}>{item.name}</Box>)}
                    </Typography>
                    <LanguageDropdown showIcon={!isMd} />
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                        onClick={toggleDrawer(true)}
                    >
                        <SvgIcon name="menu" size={24} />
                    </IconButton>
                </Toolbar>
            </Container>
        </AppBar>
        {/* </HideOnScroll> */}
        <Toolbar sx={{ height: { md: 124 } }} />
        <Drawer open={open} onClose={toggleDrawer(false)}>
            <MenuList>
                {menu.map(item => <MenuItem key={item.name}
                    selected={location.pathname === item.path}
                    onClick={toggleDrawer(false)}
                    sx={{
                        "&.Mui-selected": {
                            backgroundColor: '#EEEFF3',
                            fontWeight: '600',
                        },
                        "&.Mui-selected a": {
                            color: 'primary.main'
                        }

                    }}
                >
                    <Link className='mx-8 text-secondary no-underline' key={item.name} to={item.path}>{item.name}</Link>
                </MenuItem>)}
            </MenuList>
        </Drawer>
    </React.Fragment >
}

export default Header;