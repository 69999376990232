import appleStore from '@/assets/images/apple-store-dark.svg';
import googlePlay from '@/assets/images/google-play-dark.svg';
import huawei from '../assets/images/huawei_appgallery-dark.svg';
import { Box } from '@mui/material';

interface Props {
    aligin?: "left" | "center"
}

const AppDownload: React.FC<Props> = ({ aligin = "center" }) => {
    return (<Box sx={{ textAlign: aligin }}>
        <a className='mr-3' href='https://www.apple.com/app-store/' target='_blank' rel="noreferrer">
            <img className='mb-3 align-middle' src={appleStore} />
        </a>
        <a className='mr-3' href='https://play.google.com/store/apps' target='_blank' rel="noreferrer">
            <img className='mb-3 align-middle' src={googlePlay} />
        </a>
        <a className='mr-3' href='https://www.huawei.com/' target='_blank' rel="noreferrer">
            <img className='mb-3 align-middle' src={huawei} />
        </a>
    </Box>)
}

export default AppDownload