import { Backdrop, Box, Container, Grid, useMediaQuery, useTheme } from "@mui/material"
import Layout from "../layout/Layout"

import studyBannerZh from '@/assets/images/study-banner-zh.png'
import AppDownload from "../components/AppDownload";
import { useEffect, useState } from "react";
import contentImg1 from '@/assets/images/study-1@1x.png';
import contentImg1Bg from '@/assets/images/study-1@1x-bg.png';

import contentImg1BgBody from '@/assets/images/study-1@1x-bg-body.png';
import contentImg1BgTop from '@/assets/images/study-1@1x-bg-top.png';

import contentImg2BgBody from '@/assets/images/study-2@1x-bg-body.png';
import contentImg2BgTop from '@/assets/images/study-2@1x-bg-top.png';

import contentImg3BgBody from '@/assets/images/study-3@1x-bg-body.png';
import contentImg3BgTop from '@/assets/images/study-3@1x-bg-top.png';

import contentImg4BgBody from '@/assets/images/study-4@1x-bg-body.png';
import contentImg4BgTop from '@/assets/images/study-4@1x-bg-top.png';

import contentImg2 from '@/assets/images/study-2@1x.png';
import contentImg3 from '@/assets/images/study-3@1x.png';
import contentImg4 from '@/assets/images/study-4@1x.png';

import contentImg2cn from '@/assets/images/study-2-cn@3x.png';
import contentImg2en from '@/assets/images/study-2-en@3x.png';
import contentImg2es from '@/assets/images/study-2-es@3x.png';
import contentImg2fr from '@/assets/images/study-2-fr@3x.png';
import contentImg2id from '@/assets/images/study-2-id@3x.png';
import contentImg2my from '@/assets/images/study-2-my@3x.png';
import contentImg2th from '@/assets/images/study-2-th@3x.png';
import contentImg2vi from '@/assets/images/study-2-vi@3x.png';
import contentImg2km from '@/assets/images/study-2-km@3x.png';

import React from "react";
import DownloadDialog from "../components/DownloadDialog";
import { useTranslation } from "react-i18next";
import eventBus from "../plugins/eventBus";
import twoCircle from '@/assets/images/two-circle.svg';
import { useGetLevelList } from "../api";

import icon1 from '@/assets/images/customer@2x.png';
import icon2 from '@/assets/images/customer@2x.png';



function mdContent(items: any[], list: any[]) {
    const [hoverIndex, setHoverIndex] = useState<number>(NaN);
    const [titleHeight, setTitleHeight] = useState<any>(0);
    const { t } = useTranslation();

    function changeHover(e?: any, index?: number) {
        console.log('index', index, 'hoverIndex', hoverIndex)
        if (hoverIndex !== index && !Number.isNaN(index) ) {
            setTitleHeight(0);
            (typeof index === 'number' && !Number.isNaN(index)) && setHoverIndex(index);
            setTimeout(() => {
                const domeItem = document.querySelector('.study-item' + index)?.firstChild?.firstChild;
                if (domeItem instanceof HTMLElement) {
                    console.log(domeItem.clientHeight);
                    setTitleHeight(domeItem.clientHeight);
                }
            }, 1)
        }

    }

    // 使用 useEffect 确保 fetchData 只在组件挂载时调用
    useEffect(() => {
        changeHover(null, 0)

        const handleLanguageChange = (data: string) => {
            console.log('hoverIndex',hoverIndex)
            changeHover(null, hoverIndex)
        };

        // 组件挂载时，监听自定义事件
        eventBus.on('languageChange', handleLanguageChange);

        // 组件卸载时，移除监听器
        return () => {
            eventBus.off('languageChange', handleLanguageChange);
        };
    }, []);

    return <Grid container className="text-gray-5">
        {items.map((item, index) => <Grid className={'study-item' + index}

            item key={index}
            sx={{
                // backgroundColor: item.contentBgColor,
                zIndex: {
                    xs: 1,
                    md: index === hoverIndex ? 1 : 0
                },
                position: 'relative',
                overflow: {
                    xs: 'initial',
                    md: index === hoverIndex ? 'initial' : 'hidden'
                },
                flex: {
                    xs: 1,
                    // md: index === hoverIndex ? 'initial' : 1
                    md: index === hoverIndex ? '1' : 1
                },
                width: {
                    xs: '100%',
                    md: index === hoverIndex ? '1px' : '1px'
                    // md: index === hoverIndex ? '1px' : '1px'
                },
                left: {
                    xs: 0,
                    md: index === hoverIndex ? '0' : 0
                },
                mr: {
                    xs: 0,
                    // md: index === hoverIndex ? '-100px' : 0
                },
                pl: {
                    xs: 0,
                    // md: index === hoverIndex ? '100px' : 0
                },
                pr: {
                    xs: 0,
                    // md: index === hoverIndex ? '100px' : 0
                },
                boxSizing: {
                    xs: 'border-box',
                    md: 'content-box'
                },
                // '&::after': {
                //     content: '""',
                //     position: 'absolute',
                //     top: 0,
                //     left: 0,
                //     width: '100%',
                //     height: '100%',
                // }
            }}
            onMouseEnter={(e) => changeHover(e, index)}
        >
            <Box
                sx={{
                    position: {
                        xs: 'relative',
                        md: index === hoverIndex ? 'relative' : 'relative'
                    },
                    width: {
                        xs: '100%',
                        md: index === hoverIndex ? '400px' : '100%'
                    },
                    left: {
                        xs: 0,
                        md: index === hoverIndex ? '-50px' : 0
                    }
                }}
            >
                <Box sx={{ backgroundColor: item.topBgColor, px: 3, p: 2, height: titleHeight ? titleHeight + 'px' : 'initial' }}>
                    <Box sx={{
                        color: item.titleColor, fontSize: { md: 24 }, fontWeight: {
                            md: hoverIndex === index ? 700 : 400,
                        }
                    }} className="font-700 mb-3 text-center">
                        {index + 1}{t('level')}
                    </Box>
                    <Box className={`font-size-14px`} sx={{ display: index === hoverIndex ? 'block' : 'none' }} >
                        <span className="font-600">{t('suitableGroup')}</span>{list && list[index]?.title}<br />
                        <span className="font-600">{t('courseGoal')}</span>{list && list[index]?.des}
                    </Box>
                </Box>
                <Box sx={{
                    position: 'relative'
                }}


                >
                    <DownloadDialog>
                        {(open: boolean, setOpen: (arg0: boolean) => void) => (
                            <React.Fragment>{list ? list[index]?.courseList.length ? <img
                                className="block w-400px" src={item.topImg} alt=""
                                onClick={() => setOpen(!open)}

                            /> : null : null}
                                <Box className=" w-400px"
                                    onClick={() => setOpen(!open)}
                                    sx={{
                                        background: `url(${item.bgImg})`,
                                        mb: '-200px'
                                    }}
                                >
                                    <Grid container className="text-center relative -top-184px" >
                                        {list ? list[index]?.courseList.map((v: { id: React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }, i: number) => {
                                            return <Grid item xs={(i + 1) % 3 === 0 ? 12 : 6}
                                                className={((i - 1) % 3 === 0 && i >= 1) ? 'pr-8' : 'pr-4'}
                                                sx={{
                                                    mb: (list[index]?.courseList?.length - 1 || list[index]?.courseList?.length - 2) === i ? 0 : ((i - 2) % 3 === 0 && i >= 2) ? `${82}px` : `${i == 0 ? 110 : 110}px`,
                                                    // height: '140px',
                                                    pl: ((i - 2) % 3 === 0 && i >= 2) ? 2 : 0
                                                }}
                                                key={v.id}>
                                                <div className=" font-bold text-black">{index + 1}-{i + 1}</div>
                                                <div className="text-gray-400 font-size-3 whitespace-nowrap overflow-hidden text-ellipsis">
                                                    {v.name}
                                                </div>
                                            </Grid>
                                        }) : null}
                                    </Grid>
                                </Box>
                            </React.Fragment>

                        )}

                    </DownloadDialog>




                    {index !== hoverIndex && <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(238, 239, 243, .8)',
                            zIndex: 2,
                        }}
                    />}
                </Box>
            </Box>

        </Grid>
        )
        }
    </Grid >
}

function xsContent(items: any[], list: any[]) {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isBottom, setIsBottom] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY; // 当前滚动条距离顶部的高度
            const windowHeight = window.innerHeight; // 可视区域的高度
            const scrollHeight = document.documentElement.scrollHeight; // 页面内容的总高度

            // 判断是否滚动到底部
            if (scrollTop + windowHeight >= scrollHeight - 100) {
                setIsBottom(true);
            } else {
                setIsBottom(false);
            }
            // 这里可以执行你需要的逻辑，比如加载更多内容等
        };


        window.addEventListener('scroll', handleScroll);

        // 清除事件监听器
        return () => {
            window.removeEventListener('scroll', handleScroll);

        };
    }, []);
    return <React.Fragment>
        <Box
            sx={{
                position: isBottom ? 'static' : 'sticky',
                top: 56,
                zIndex: 1
            }}
        >
            <Grid container className="">
                {items.map((item, index) => <Grid item key={index} xs={3} onClick={() => setActiveIndex(index)}>
                    <Box sx={{
                        backgroundColor: item.topBgColor, py: 2,
                        color: item.titleColor, fontWeight:
                            activeIndex === index ? 700 : 400,
                    }} className="text-center">
                        {index + 1}{t('level')}
                    </Box>
                </Grid>
                )}
            </Grid>
            <Box className={`font-size-14px pa-4 text-gray-5`} sx={{ backgroundColor: items[activeIndex].topBgColor }} >
                <span className="font-600">{t('suitableGroup')}</span>{list ? list[activeIndex]?.title : null}<br />
                <span className="font-600">{t('courseGoal')}</span>{list ? list[activeIndex]?.des : null}
            </Box>
        </Box>
        {/* <DownloadDialog>
            {(open: boolean, setOpen: (arg0: boolean) => void) => (
                <img src={items[activeIndex].contentImg} alt="" className="w-full" onClick={() => setOpen(!open)} />
            )}
        </DownloadDialog> */}
        {list ? list[activeIndex]?.courseList?.length ? <img className="block w-full" src={items[activeIndex].topImg} alt="" /> : null : null}

        <Box className=""
            sx={{
                background: `url(${items[activeIndex].bgImg})`,
                backgroundSize: "100%",
                // mb: '-200px'
            }}
        >
            {/* <DownloadDialog>
                            {(open: boolean, setOpen: (arg0: boolean) => void) => (
                                <img src={item.bgImg} alt="" className="w-100 absolute top-0" onClick={() => setOpen(!open)} />
                            )}
                        </DownloadDialog> */}
            <Grid container className="text-center relative -top-184px -mb-326px" >
                {list ? list[activeIndex]?.courseList.map((v: { id: React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }, i: number) => {
                    return <Grid item xs={(i + 1) % 3 === 0 ? 12 : 6}
                        className={((i - 1) % 3 === 0 && i >= 1) ? 'pr-8' : 'pr-4'}
                        sx={{
                            mb:`calc(${(list ? ((list[activeIndex]?.courseList?.length - 1) || (list[activeIndex]?.courseList?.length - 2)) === i ? 0 : ((i - 2) % 3 === 0 && i >= 2) ? `${82}px` : `${i == 0 ? 110 : 110}px` : null)} - ${i*0.5}px)`,
                            // height: '140px',
                            pl: ((i - 2) % 3 === 0 && i >= 2) ? 2 : 0
                        }}
                        key={v.id}>
                        <div className=" font-bold text-black">{activeIndex + 1}-{i + 1}</div>
                        <div className="text-gray-400 font-size-3 whitespace-nowrap overflow-hidden text-ellipsis">
                            {v.name}
                        </div>
                    </Grid>
                }) : null}
            </Grid>
        </Box>

    </React.Fragment >
}




const Study: React.FC = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();
    const { fetchLevelList, response, loading } = useGetLevelList()

    const list = response || []
    // const [items, setItems] = useState<any[]>([]);
    const items = [
        {
            topBgColor: '#D7E9ED',
            titleColor: '#4B8794',
            contentBgColor: '#EBF3F5',
            // contentImg: contentImg4,
            bgImg: contentImg1BgBody,
            topImg: contentImg1BgTop,
            people: t('levelTwoPeople'),
            target: t('levelOneTarget')
        },
        {
            topBgColor: '#CCDBD0',
            titleColor: '#598866',
            contentBgColor: '#F2F7F4',
            // contentImg: contentImg1,
            bgImg: contentImg2BgBody,
            topImg: contentImg2BgTop,

            people: t('levelOnePeople'),
            target: t('levelTwoTarget')
        },
        {
            topBgColor: '#E6DCD5',
            titleColor: '#8F7D70',
            contentBgColor: '#F6F0ec',
            // contentImg: contentImg2,
            bgImg: contentImg3BgBody,
            topImg: contentImg3BgTop,

            people: t('levelThreePeople'),
            target: t('levelThreeTarget')
        },
        {
            topBgColor: '#D5DDE0',
            titleColor: '#607983',
            contentBgColor: '#eff1f2',
            // contentImg: contentImg3,
            bgImg: contentImg4BgBody,
            topImg: contentImg4BgTop,

            people: t('levelFourPeople'),
            target: t('levelFourTarget')
        }
    ]
    function changeItems() {
        // let contentImg1 = contentImg2cn


        // switch (i18n.language) {
        //     case 'zh':
        //         contentImg1 = contentImg2cn;
        //         break;
        //     case 'en':
        //         contentImg1 = contentImg2en;
        //         break;
        //     case 'es':
        //         contentImg1 = contentImg2es;
        //         break;
        //     case "fr":
        //         contentImg1 = contentImg2fr;
        //         break;
        //     case "id":
        //         contentImg1 = contentImg2id;
        //         break;
        //     case "my":
        //         contentImg1 = contentImg2my;
        //         break;
        //     case "th":
        //         contentImg1 = contentImg2th;
        //         break;
        //     case "vi":
        //         contentImg1 = contentImg2vi;
        //         break;
        //     case "km":
        //         contentImg1 = contentImg2km;
        //         break;
        //     default:
        //         break;
        // }
        // items[1].contentImg = contentImg1;
        // setItems([...items])
    }


    // 使用 useEffect 确保 fetchData 只在组件挂载时调用
    useEffect(() => {
        changeItems()
        fetchLevelList()

        const handleLanguageChange = (data: string) => {
            changeItems()
            fetchLevelList()

        };

        // 组件挂载时，监听自定义事件
        eventBus.on('languageChange', handleLanguageChange);

        // 组件卸载时，移除监听器
        return () => {
            eventBus.off('languageChange', handleLanguageChange);
        };
    }, []);

    return (
        <Layout className="pb8">
            <Box component='section' sx={{ background: 'linear-gradient( 180deg, #F9FAF9 0%, #E5EDE7 100%)' }}>
                <Container className="relative"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    <Box className="max-w-500px" sx={{
                        position: {
                            xs: 'static',
                            md: 'absolute'
                        },
                        let: 84,
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        pb: 4,
                        pt: {
                            xs: 0,
                            md: 2.5
                        },
                        textAlign: {
                            xs: 'center',
                            md: 'left'
                        },
                        mt: {
                            xs: 2.5,
                            md: 0
                        }
                    }}>
                        <div>
                            <img className='mb-3' src={twoCircle} />

                        </div>

                        <div className='font-700 font-size-30px mb-3'>
                            {t('customCourseTitle')}
                            <span className='text-primary'>{t('exclusiveFreeCourse')}</span>
                        </div>
                        <Box sx={{
                            maxWidth: {
                                xs: '100%',
                                md: 375
                            }
                        }} className="text-gray-800 text-opacity-60 mb-6 font-size-14px">
                            {t('courseDescription1')}<br />
                            {t('courseDescription2')}
                        </Box>
                        <AppDownload aligin="left" />
                        <div className="text-primary font-size-14px mt-3">
                            {t('downloadSTOChinese')} &emsp;|&emsp; {t('startLearningJourney')}
                        </div>
                    </Box>
                    <Box component='img' src={studyBannerZh} sx={{
                        width: '100%',
                        display: 'block',
                        marginLeft: {
                            xs: 'initial',
                            md: '580px'
                        },
                        maxWidth: {
                            xs: '100%',
                            md: 650
                        }
                    }}
                    ></Box>
                </Container>
            </Box>
            <Container sx={{ px: { xs: 0 }, overflow: { xs: 'initial', md: 'initial' } }}>
                {isMd ? xsContent(items, list) : mdContent(items, list)}
            </Container>
        </Layout >
    )
}

export default Study