// eventBus.ts
type EventCallback = (...args: any[]) => void;

class EventBus {
  private events: { [key: string]: EventCallback[] } = {};

  // 监听事件
  on(event: string, callback: EventCallback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  }

  // 移除监听器
  off(event: string, callback: EventCallback) {
    if (!this.events[event]) return;

    this.events[event] = this.events[event].filter((cb) => cb !== callback);
  }

  // 触发事件
  emit(event: string, ...args: any[]) {
    if (!this.events[event]) return;

    this.events[event].forEach((callback) => callback(...args));
  }
}

export default new EventBus();
