import { makeStyles } from '@mui/styles'

import Layout from "../layout/Layout"
import arrBg from '@/assets/images/arrow@2x.png'
import { Box, Button, Container, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import twoCircle from '@/assets/images/two-circle.svg';
import coursePoster from '@/assets/images/course-poster.jpg'
import { useGetCourseDescription, useGetCourseList } from '../api';
import { useEffect } from 'react';
import React from 'react';
import SvgIcon from '../components/SvgIcon';
import DownloadDialog from '../components/DownloadDialog';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import eventBus from '../plugins/eventBus';
import { useActivate, useUnactivate } from 'react-activation';
const useStyles = makeStyles(({
    section: {
        background: 'linear-gradient( 180deg, #F9FAF9 0%, #EFF5F1 100%)'
    },
}))

const SkeletonContent = () => {
    return (
        <React.Fragment>
            {Array.from(Array(4)).map((_, index) =>
            (<Grid justifyContent={'center'} className='flex' item xs={12} md={6} key={index}>
                <div className='rounded-4 mb-10 bg-white w-81 overflow-hidden'>
                    <Skeleton variant="rectangular" height={183} animation="wave" />
                    <div className='p-4'>
                        <Typography component="div" variant="h3">
                            <Skeleton />
                        </Typography>

                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <div className='mt-4'></div>
                        <Skeleton variant='rounded' height={44}></Skeleton>
                    </div>

                </div>


            </Grid>
            ))}
        </React.Fragment>)
}

let itemId = '';
const ListContent = (list: any[], t: TFunction<"translation", undefined>, isFullScreen: boolean) => {
    function fullPlayVideo(item: any) {
        const videoDom = document.getElementById(item.id) as HTMLVideoElement;
        videoDom?.play();
        videoDom?.requestFullscreen();
        videoDom?.setAttribute('controls', 'controls');
        itemId = item.id
    }

    if (!isFullScreen) {
        const videoDom = document.getElementById(itemId) as HTMLVideoElement;
        videoDom?.pause();
        videoDom?.removeAttribute('controls');
    }

    return (
        <React.Fragment>
            {list.map((item, index) => (
                (<Grid justifyContent={'center'} className='flex' item xs={12} md={6} key={item.id}>
                    <div className='rounded-4 flex flex-col mb-10 bg-white w-81 overflow-hidden'>
                        <div className='h-183px relative'>
                            {item.coverType === 2 ? <video id={item.id} className='w-full h-full' src={item.cover} x5-video-player-type="h5"
                                x5-video-player-fullscreen="true"
                                x5-video-orientation="portraint" /> : <img className='align-middle object-cover' height={'100%'} width={'100%'} src={item.cover || coursePoster} alt="" />}
                            {item.coverType === 2 ? <div className="bg-black bg-opacity-40 absolute w-full h-full top-0 flex items-center justify-center" onClick={() => fullPlayVideo(item)}>
                                <SvgIcon name='arrowRightRoundedIcon' color='white' size={50}></SvgIcon>
                            </div>
                                : null}
                        </div>

                        <div className='p-4 flex flex-col flex-1 justify-between'>
                            <div>
                                <h3 className='font-size-6 mb-3 mt-0'>
                                    {item.title}
                                </h3>
                                <p className='text-gray-800 text-opacity-60 font-size-14px m-0 line-clamp-6 mb-3'>
                                    {item.introduce}
                                </p>
                            </div>


                            <DownloadDialog>
                                {(open: boolean, setOpen: (arg0: boolean) => void) => (
                                    <Button variant="contained" color="inherit" size="large" onClick={() => setOpen(!open)} sx={{
                                        color: '#fff', width: '100%', backgroundColor: '#333', borderRadius: 2, height: 44, fontSize: 14, '&:hover': {
                                            backgroundColor: '#555',
                                        },
                                    }}>
                                        {t('viewCourse')}
                                    </Button>
                                )}
                            </DownloadDialog>

                        </div>
                    </div>
                </Grid>)
            ))}
        </React.Fragment >
    )
}


const Course: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation()
    const [isFullScreen, setIsFullScreen] = React.useState(false);


    const { response, loading, fetchCourseList } = useGetCourseList();
    const { response: description, loading: descriptionLoading, fetchCourseDescription } = useGetCourseDescription();

    const listData = response?.records || [];

    function fullStopVideo(item: any) {
        console.log('fullscreenchange', document.fullscreenElement)

        setIsFullScreen(!!document.fullscreenElement)

    }

    useEffect(() => {
        fetchCourseList();
        fetchCourseDescription();
        const handleLanguageChange = (data: string) => {
            fetchCourseList();
            fetchCourseDescription();
        };

        // 组件挂载时，监听自定义事件
        eventBus.on('languageChange', handleLanguageChange);

        // 监听退出全屏
        document.addEventListener('fullscreenchange', fullStopVideo)


        // 组件卸载时，移除监听器
        return () => {
            eventBus.off('languageChange', handleLanguageChange);
            document.removeEventListener('fullscreenchange', fullStopVideo)

        };
    }, []);

    function TestFunction() {
        useActivate(() => {
            fetchCourseList();
        })

        useUnactivate(() => {
        })
        return null
    }

    return (
        <Layout>
            <TestFunction />
            <section className={classes.section}>
                <Container>

                    <Box
                        sx={{
                            pt: {
                                xs: 3,
                                md: 10
                            },
                            background: {
                                md: `url(${arrBg}) no-repeat top center/contain`
                            }
                        }}>
                        {
                            isMd ? <div className='text-center mb-3'> <img src={twoCircle} alt="" /></div> : null
                        }

                        <Box
                            sx={
                                {
                                    fontSize: {
                                        xs: 24,
                                        md: 30
                                    }
                                }
                            }
                            className='font-700 mb-3 text-center'>
                            <span className=' text-primary'>{t('progressivecourse')}</span>{t('difficulty')}
                            {isMd ? <br /> : null}
                            {t('courseTheme')}
                        </Box>
                        <Box sx={{
                            textAlign: 'center',
                            mb: {
                                xs: 3,
                                md: 10
                            }
                        }} className="text-gray-800 text-opacity-60 mb-6 font-size-14px">
                            {descriptionLoading ? <React.Fragment>
                                <Box className="flex justify-center items-center flex-col" sx={{ width: 300, margin: '0 auto' }}>
                                    <Skeleton animation="wave" width={'100%'} />
                                    <Skeleton animation="wave" width={'70%'} />
                                    <Skeleton animation="wave" width={'80%'} />
                                </Box>
                            </React.Fragment>
                                :
                                <div dangerouslySetInnerHTML={{ __html: description?.[isMd ? 'h5Des' : 'pcDes'] }}>
                                </div>
                            }
                        </Box>
                        <Grid container sx={{
                            px: {
                                xs: 0,
                                md: 25
                            },
                        }}>

                            {loading ? SkeletonContent() : ListContent(listData, t, isFullScreen)}

                        </Grid>
                    </Box>

                </Container>
            </section>
        </Layout >
    )
}

export default Course