import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles'
import { Container, Box, BoxProps, Button, Link, useMediaQuery, useTheme } from '@mui/material';
import LanguageDropdown from '../components/LanguageDropdown';

import appleStore from '@/assets/images/apple-store-light.svg';
import googlePlay from '@/assets/images/google-play-light.svg';
import huawei from '../assets/images/huawei_appgallery-light.svg';
import SvgIcon from '../components/SvgIcon';
import qrcode from '@/assets/images/qrcode.jpg';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetAppDownload, useGetIpCountry, useGetSocialList } from '../api';

const useStyles = makeStyles({
    footer: {
        background: 'linear-gradient( 146deg, #3A3D3D 0%, #363C3C 98%)',
    }
});

function Item(props: BoxProps) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{

                ...sx,
            }}
            {...other}
        />
    );
}

const Footer = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const { response: list, loading, fetchSocialList } = useGetSocialList()
    const { response: country, loading: loadingCountry, fetchIpCountry } = useGetIpCountry()
    const { response: app, loading: loadingApp, fetchAppDownload } = useGetAppDownload()
    const appStoreUrl = useRef()
    useEffect(() => {
        fetchSocialList()
        fetchIpCountry().then(res => {
            const isCN = JSON.parse(country?.place || '{}').countryCode === 'CN'


            // appStore
            fetchAppDownload({ packageType: 0, channelType: isCN ? 2 : 1, version: '1' }).then(res => {

                appStoreUrl.current = res
            })
            // android
            fetchAppDownload({ packageType: 1, channelType: isCN ? 3 : 0, verson: '1' }).then(res => {

            })
            // huawei
            // fetchAppDownload({ packageType: 2, packageType: isCN ? 4 : 2 }).then(res => {

            // })
        })
    }, [])
    return <footer className={classes.footer + ' text-white relative z-1'}>
        <Container>
            <Box sx={{
                display: 'grid', gridTemplateColumns: {
                    xs: '1fr',
                    md: 'repeat(3, 1fr)'
                }, padding: {
                    md: 5,
                },
                py: {
                    xs: 3
                }
            }}>
                <Item sx={
                    {
                        display: {
                            xs: 'flex',
                            md: 'block'
                        }
                    }
                }>
                    <RouterLink to="/" className={(isMd ? 'size-50px font-size-14px mr-2' : 'size-21 font-size-6 mb-6') + ' transition-all bg-primary  flex items-center decoration-none justify-center text-white font-700 flex-shrink-0'}>
                        LOGO
                    </RouterLink>
                    <Box sx={{ display: { xs: 'flex', md: 'block' }, flexDirection: { xs: 'column', md: 'row' }, flexGrow: { xs: 1 } }}>

                        <div className={(isMd ? 'font-size-4' : 'font-size-6' + '  font-600 mb-1')}>
                            STO Chinese
                        </div>
                        <Box sx={{ fontSize: { xs: '14px', md: '16px' } }} className='mb-6'>
                            {t('inHandChineseMaster')}
                        </Box>
                    </Box>
                    <LanguageDropdown position='bottom' />
                </Item>
                <Item sx={{
                    marginBottom: {
                        xs: '24px',
                        md: 0
                    }
                }}>
                    <Box sx={{ textAlign: { xs: 'center', md: 'left' }, marginBottom: { xs: 1.5, md: 0.5 } }} className={(isMd ? 'font-size-4' : 'font-size-6') + ' font-600'}>
                        {t('downloadSTOChinese')}
                    </Box>
                    {isMd ? null : (
                        <Box className='mb-6'>
                            {t('startLearningJourney')}
                        </Box>
                    )}
                    <Box sx={{
                        display: {
                            xs: 'flex',
                            md: 'block'
                        }, justifyContent: 'center',
                        flexWrap: {
                            xs: 'wrap',
                        },
                        gap: '12px'
                    }}>
                        <a href='https://www.apple.com/app-store/' target='_blank' rel="noreferrer">
                            <img alt='apple store' width={isMd ? 145 : ''} className={(isMd ? '' : 'mb-3') + ' align-middle'} src={appleStore} />
                        </a>
                        <a href='https://play.google.com/store/apps' target='_blank' rel="noreferrer">
                            <img alt='google play' width={isMd ? 145 : ''} className={(isMd ? '' : 'mb-3') + ' align-middle'} src={googlePlay} />
                        </a>
                        <a href='https://www.huawei.com/' target='_blank' rel="noreferrer">
                            <img alt='huawei' width={isMd ? 145 : ''} className={(isMd ? '' : 'mb-3') + ' align-middle'} src={huawei} />
                        </a>
                    </Box>
                </Item>
                <Item sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Box sx={{ fontSize: { xs: 16, md: 24 } }} className='font-600 mb-1'>
                        {t('followUs')}
                    </Box>
                    <Box sx={{
                        fontSize: { xs: 12, md: 16 }, marginBottom: {
                            xs: 2,
                            md: 3
                        }
                    }} className='mb-6'>
                        E-mail：
                        <Link className='text-primary' href='mailto:support@studytrustintl.com.sg' underline='none'>
                            support@studytrustintl.com.sg
                        </Link>
                    </Box>
                    <img className='mb-6 align-middle' width={isMd ? 80 : 200} src={qrcode} />
                    <Box className='flex gap-4 pl-3' sx={{
                        justifyContent: {
                            xs: 'center',
                            md: 'flex-start'
                        },
                        pl: {
                            xs: 0,
                            md: 1.5
                        }
                    }}>
                        {list?.map((item: any, index: number) => {
                            return (
                                <Link key={index} color="inherit" underline='none' href={item.stoUrl} target="_blank">
                                    <Box component="img" src={item.icon}
                                        sx={{
                                            objectFit: 'cover',
                                            width: {
                                                xs: 32,
                                                md: 48
                                            },
                                            height: {
                                                xs: 32,
                                                md: 48
                                            }
                                        }}
                                    ></Box>
                                </Link>
                            )
                        })}
                        {/* <Link color="inherit" underline='none' href="https://www.facebook.com/" target="_blank">
                            <SvgIcon name="facebook_circle" size={isMd ? 32 : 48} />
                        </Link>
                        <Link color="inherit" underline='none' href="https://twitter.com/" target="_blank">
                            <SvgIcon name="twitter-circle" size={isMd ? 32 : 48} />
                        </Link>
                        <Link color="inherit" underline='none' href="https://www.instagram.com/" target="_blank">
                            <SvgIcon name="instagram-circle" size={isMd ? 32 : 48} />
                        </Link> */}
                    </Box>
                </Item>
            </Box>
        </Container >
        <Box sx={{
            mx: {
                xs: 2,
                md: 0
            }
        }} className='bg-tertiary h-1px' />
        <Container className='text-center '>
            <Box sx={{
                py: {
                    xs: 3,
                    md: 2
                },
                fontSize: {
                    xs: 12,
                    md: 16
                }
            }} className='flex justify-center items-center'>
                {t('copyright')}
            </Box>
        </Container>
    </footer >;
};

export default Footer;
