import { Dialog, DialogContent, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material"
import React from "react";
import AppDownload from "./AppDownload";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const DownloadDialog: React.FC<any> = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    return (<React.Fragment>
        {/* 提供 setOpen 作为子组件的 prop */}
        {children(open, setOpen)}
        <Dialog
            open={open}
            onClose={handleClose}
            sx={
                {
                    borderRadius: '16px',
                }
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                > <CloseIcon />
                </IconButton>
                <Grid container mb={4} gap={3} mt={2} direction={isMd ? "column" : "row"} alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <div className={(isMd ? 'size-15 font-size-17px' : 'size-21 font-size-6') + ' transition-all bg-primary  flex items-center justify-center text-white font-700'}>
                            LOGO
                        </div>
                    </Grid>
                    <Grid item >
                        <div className={(isMd ? '' : 'font-size-6') + " font-600 mb-1"}>
                            {t('downloadSTOChinese')}

                        </div>
                        <div>
                            {t('startLearningJourney')}
                        </div>
                    </Grid>
                </Grid>

                <AppDownload />
            </DialogContent>
        </Dialog>
    </React.Fragment>
    )
}

export default DownloadDialog

