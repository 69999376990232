import { Dialog, DialogContent, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Link } from 'react-router-dom';
import SvgIcon from "../components/SvgIcon"
import customer from '@/assets/images/customer@2x.png'
import React from "react";
import AppDownload from "../components/AppDownload";
import DownloadDialog from "../components/DownloadDialog";

const Tools = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth'
    });
  };



  return <React.Fragment>
    <div className="pos-fixed top-70 w-14 right-4 z-1">
      <DownloadDialog>
        {(open: boolean, setOpen: (arg0: boolean) => void) => (
          <SvgIcon className="mb-2 cursor-pointer" name="download" onClick={() => setOpen(!open)} size={60}></SvgIcon>
        )}
      </DownloadDialog>
      <Link to="/customer">
        <img className="mb-2" src={customer} width="60" />
      </Link>
      <SvgIcon className="cursor-pointer" onClick={scrollToTop} name="top" size={60}></SvgIcon>
    </div>

  </React.Fragment>
}
export default Tools