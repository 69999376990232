import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import zhTranslation from "./locales/zh/translation.json";
import esTranslation from "./locales/es/translation.json";
import myTranslation from "./locales/my/translation.json";
import frTranslation from "./locales/fr/translation.json";
import idTranslation from "./locales/id/translation.json";
import thTranslation from "./locales/th/translation.json";
import msTranslation from "./locales/ms/translation.json";
import kmTranslation from "./locales/km/translation.json";
import viTranslation from "./locales/vi/translation.json";

const storedLanguage = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    zh: {
      translation: zhTranslation,
    },
    es: {
      translation: esTranslation,
    },
    my: {
      translation: myTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    id: {
      translation: idTranslation,
    },
    th: {
      translation: thTranslation,
    },
    ms: {
      translation: msTranslation,
    },
    km: {
      translation: kmTranslation,
    },
    vi: {
      translation: viTranslation,
    },
  },
  lng: storedLanguage || 'zh', // 默认语言
  fallbackLng: "en", // 如果当前语言的翻译不存在，则回退到该语言
  interpolation: {
    escapeValue: false, // React已经对XSS进行防护，不需要转义
  },
});

export default i18n;
