import { AxiosRequestConfig } from "axios";
import { useAxios } from "../hooks/useAxios";
import { BannerPostParams } from "../types/api.type";

// 获取banner列表
export const useGetBannerList = () => {
  const { response, loading, error, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchBannerList = () => {
    fetchData({
      url: "official/banner/list",
      method: "GET",
    });
  };
  return { response, loading, error, fetchBannerList };
};
// 统计banner
export const usePostBanner = () => {
  const { response, loading, error, fetchData } = useAxios();
  const fetchPostBanner = (data: BannerPostParams) => {
    fetchData({
      url: "official/banner/access",
      method: "POST",
      data,
    });
  };
  return { response, loading, error, fetchPostBanner };
};

// 获取关于我们内容
export const useGetAbout = () => {
  const { response, loading, error, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchAbout = () => {
    return fetchData({
      url: "official/aboutus",
      method: "GET",
    });
  };
  return { response, loading, error, fetchAbout };
};

// 获取课程页面描述
export const useGetCourseDescription = () => {
  const { response, loading, error, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchCourseDescription = () => {
    fetchData({
      url: "official/course/desc",
      method: "GET",
    });
  };
  return { response, loading, error, fetchCourseDescription };
};

// 获取课程列表
export const useGetCourseList = () => {
  const { response, loading, error, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchCourseList = () => {
    fetchData({
      url: "official/course/list",
      method: "GET",
    });
  };
  return { response, loading, error, fetchCourseList };
};

// 获取知识分类tree
export const useGetKnowledgeTree = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchKnowledgeTree = () => {
    fetchData({
      url: "official/knowledge/classifies",
      method: "GET",
    });
  };
  return { response, loading, fetchKnowledgeTree };
};

// 获取知识列表
export const useGetKnowledgeList = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchKnowledgeList = (params: any) => {
    return fetchData({
      url: "official/knowledge/list",
      method: "GET",
      params,
    });
  };
  return { response, loading, fetchKnowledgeList };
};

//获取知识详情
export const useGetKnowledgeDetail = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchKnowledgeDetail = (params: any) => {
    fetchData({
      url: "official/knowledge/detail",
      method: "GET",
      params,
    });
  };
  return { response, loading, fetchKnowledgeDetail };
};

// 获取智能客服常见问题
export const useGetQuestionList = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchQuestionList = () => {
    fetchData({
      url: "common/app/custom/greeting",
      method: "GET",
    });
  };
  return { response, loading, fetchQuestionList };
};

// 获取客户提问回复
export const useGetQuestionReply = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchQuestionReply = (params: any) => {
    return fetchData({
      url: "common/app/custom/ask",
      method: "POST",
      params,
    });
  };
  return { response, loading, fetchQuestionReply };
};

// 获取社交平台列表
export const useGetSocialList = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchSocialList = () => {
    fetchData({
      url: "official/social/list",
      method: "GET",
    });
  };
  return { response, loading, fetchSocialList };
};

// 获取水平等级及课程列表
export const useGetLevelList = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchLevelList = () => {
    fetchData({
      url: "/common/app/official/level",
      method: "GET",
    });
  };
  return { response, loading, fetchLevelList };
};

// 获取ip国家
export const useGetIpCountry = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchIpCountry = () => {
    return fetchData({
      url: "/common/app/custom/ipInfo",
      method: "POST",
    });
  };
  return { response, loading, fetchIpCountry };
};

// 获取app下载地址
export const useGetAppDownload = () => {
  const { response, loading, fetchData } = useAxios();
  // 预配置的请求配置
  const fetchAppDownload = (params:any) => {
    return fetchData({
      url: "/common/app/version/new",
      method: "GET",
      params,
    });
  };
  return { response, loading, fetchAppDownload };
};
