import { AppBar, Avatar, Box, Button, CircularProgress, Container, Grid, IconButton, Link, Skeleton, TextareaAutosize, TextField, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material"
import Layout from "../layout/Layout"
import { makeStyles } from "@mui/styles"
import circles from '@/assets/images/circles@2x.png'
import customerBg from '@/assets/images/customer_bg.png'
import twoCircle from '@/assets/images/two-circle.svg';
import SvgIcon from "../components/SvgIcon"
import customer from '@/assets/images/customer@3x.png'
import triangleIcon from '@/assets/images/triangle.svg'
import { ArrowLeft, ChevronLeft, RampRight } from "@mui/icons-material"
import { useGetQuestionList, useGetQuestionReply } from "../api"
import { useEffect } from "react"
// import LoadingButton from '@mui/lab/LoadingButton';

import React from "react"
import { useTranslation } from "react-i18next"
import eventBus from "../plugins/eventBus"
import zIndex from "@mui/material/styles/zIndex"
const useStyles = makeStyles(({
    section: {
        background: `url(${customerBg}),linear-gradient( 180deg, #F9FAF9 0%, #EFF5F1 100%) no-repeat center center/contain`,
    },
    bgColorCss: {
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            background: '#EEEFF3',
            zIndex: -1
        }
    }
}))


function formatTime(date: Date): string {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);

    if (date >= today) {
        return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    } else if (date >= yesterday) {
        return `昨天 ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    } else {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    }
}

function shouldShowTime(current: Date, previous: Date): boolean {
    const timeDiff = (current.getTime() - previous.getTime()) / 1000 / 60; // 转换为分钟
    return timeDiff > 5; // 超过5分钟则显示时间
}

interface Message {
    content: string;
    timestamp: Date;
    formattedTimestamp?: string;
}

let previousTimestamp: Date = new Date(); // 用于记录前一条消息的时间
const now = new Date();
const About: React.FC = () => {

    const classes = useStyles()
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const [list, setValue] = React.useState<any>([]);
    const [content, setContent] = React.useState('')
    const [sessionId, setSession] = React.useState('')
    const [inputHeight, setInputHeight] = React.useState(0)
    const { t } = useTranslation();


    const { response: data, loading, fetchQuestionList } = useGetQuestionList();
    const { response: reply, loading: replyLoading, fetchQuestionReply } = useGetQuestionReply();

    // console.log(data)
    useEffect(() => {
        fetchQuestionList();

        const handleLanguageChange = (data: string) => {
            fetchQuestionList();
        };

        // 组件挂载时，监听自定义事件
        eventBus.on('languageChange', handleLanguageChange);
        setTimeout(() => {
            setInputHeight(document.querySelector('#replyContainer')?.clientHeight || 0)

        })

        // 组件卸载时，移除监听器
        return () => {
            eventBus.off('languageChange', handleLanguageChange);
        };
    }, [])

    function handleReply(item: any) {
        let data
        let params
        if (item.type === 'submit') {
            item.preventDefault();
            params = { keyword: content }
            data = [{ content, type: 2, timestamp: new Date() }]
        } else {
            params = { keyword: item.name || t('noAnswer') }
            data = [{
                type: 2,
                content: item.name || t('noAnswer'),
                ...item,
                timestamp: new Date()
            }]
        }
        list.push(...data)
        updateList()
        setContent('')
        fetchQuestionReply({
            ...params,
            sessionId
        }).then((res) => {
            console.log('res', res)
            setSession(res.sessionId)
            const replyList = res.replyList;
            if (replyList && replyList.length > -1) {
                list.push({
                    type: 1,
                    content: replyList.length ? replyList[replyList.length - 1].autoValue : t('noAnswer'),
                    timestamp: new Date()
                })
            }
            updateList()
            setTimeout(() => {
                if (isMd) {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: 'smooth'
                    })

                } else {
                    setTimeout(() => {
                        const dom = document.querySelector('#customerContainer') as HTMLDivElement
                        dom.scrollTop = dom.scrollHeight
                    })
                }
            }, 300)
        })
    }

    function updateList() {
        setValue(list.map((message: Message) => {
            // 如果是第一条消息，或者超过5分钟，则显示时间
            if (!previousTimestamp || shouldShowTime(message.timestamp, previousTimestamp)) {
                console.log(formatTime(message.timestamp)); // 显示时间
                message.formattedTimestamp = formatTime(message.timestamp)
            }

            // 显示消息内容
            console.log(message.content);

            // 更新上一个消息的时间戳
            previousTimestamp = message.timestamp;
            return message
        }))
    }

    return (
        <Layout>
            {isMd ?
                <AppBar position="fixed" color="transparent" sx={{ backgroundColor: 'white !important', boxShadow: 'none' }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => history.back()}
                        >
                            <ChevronLeft />
                        </IconButton>
                        <Typography component="div" sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 500, fontSize: 18 }}>
                            {t('onlineCustomerService')}
                        </Typography>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ visibility: 'hidden' }}
                        >
                            <ChevronLeft />
                        </IconButton>
                    </Toolbar>
                </AppBar> : null
            }
            <Box component={'section'} className={isMd ? 'bg-#EEEFF3' : classes.section}

            >
                <Container className="py-5" sx={{
                    padding: {
                        xs: 0,
                    },
                    display: {
                        xs: 'flex',
                        md: 'block',

                    },
                    flexDirection: {
                        xs: 'column',
                    },
                    height: {
                        xs: 'auto',
                        md: '100%'
                    },
                    overflow: 'hidden',
                }} >
                    <Box sx={{
                        borderRadius: 4,
                        backgroundColor: {
                            xs: 'transparent',
                            md: '#EEEFF3'
                        },
                        height: {
                            xs: '100%', md: 'initial'
                        },
                        pt: {
                            xs: '53px', md: 0
                        },
                        pb: {
                            xs: inputHeight + 'px', md: 0
                        },
                        overflowY: 'auto',
                        flex: 1

                    }}
                        className={classes.bgColorCss}
                    >
                        {isMd ? null : <Box display="flex" justifyContent={{ md: 'space-between' }} sx={{
                            background: 'linear-gradient( 180deg, #F7FCF9 0%, #DFEBE3 100%)',
                            pl: 2.5,
                            pr: 3.5
                        }}>
                            <div className="flex items-center">
                                <SvgIcon name="message" size={40} />
                                <Box sx={{ fontWeight: 600, fontSize: 22, ml: 1 }}>
                                    {t('onlineCustomerService')}
                                </Box>
                            </div>
                            <div className="flex items-center mt-10px">
                                <div className="flex items-center">
                                    <div className="text-primary rounded-1 pa-1" style={{ backgroundColor: '#DFF6E7' }}>{t('hiService')}
                                    </div>
                                    <img src={triangleIcon} />
                                </div>
                                <img src={customer} className="ml-2" width="60" />
                            </div>
                        </Box>}
                        <Box id="customerContainer" className="overflow-y-auto px-5 font-size-14px"
                            sx={{
                                height: {
                                    xs: '100%',
                                    md: 503
                                }
                            }}
                        >
                            <div className="text-center text-gray-500  my-4">{formatTime(now)}</div>
                            <div className="flex gap-3 mb-5 pr-53px">
                                <Avatar alt="customer" src={data?.customerAvatar} sx={{ width: 40, height: 40, borderRadius: 2, bgcolor: '#E6E6E6' }}></Avatar>
                                <Box className="bg-white pa-3 rounded-3 rounded-tl-1">
                                    <div className="font-size-15px ">{t('intelligentService')}</div>
                                    <div className="font-size-3 text-gray-500 mb-2">{t('clickToGetAnswer')}</div>
                                    <div className="font-size-3 flex flex-col items-start">
                                        {loading ? Array.from({ length: 5 }).map((_, index: number) => (
                                            <Skeleton width={Math.round(50 + Math.random() * 50) + '%'} animation="wave" key={index} />
                                        )) : data?.customGreetings?.map((item: { name: any }, index: number) => (
                                            (<Link underline={'none'} sx={{ color: '#56B8FE', mb: 0.5, cursor: 'pointer' }} key={index} onClick={() => handleReply(item)}>{index + 1}.{item.name || t('noAnswer')}</Link>)
                                        ))}
                                    </div>
                                </Box>
                            </div>
                            {list.map((item: { type: number; formattedTimestamp?: string, content: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined }, index: React.Key | null | undefined) => (
                                <React.Fragment key={index}>
                                    {item.formattedTimestamp && <div className="text-center text-gray-500  my-4">{item.formattedTimestamp}</div>}
                                    <Box className="flex gap-3 mb-5 flex-row-reverse"
                                        sx={{
                                            flexDirection: item.type === 2 ? 'row-reverse' : 'row',
                                            pl: item.type === 2 ? '53px' : 0,
                                            pr: item.type === 1 ? '53px' : 0
                                        }}

                                    >
                                        <Avatar src={item.type === 2 ? '' : data?.customerAvatar} sx={{ width: 40, height: 40, borderRadius: 2, }}></Avatar>
                                        <div
                                            className={item.type === 2 ? 'bg-primary text-white pa-3 rounded-3 rounded-tr-1' : 'bg-white pa-3 rounded-3 rounded-tl-1'}
                                        >
                                            {item.content}
                                        </div>
                                    </Box>
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        id="replyContainer"
                        component={'form'}
                        onSubmit={handleReply}
                        display="flex" sx={{
                            p: 2, position: { xs: 'fixed', md: 'relative', }, bottom: 0, width: '100%',
                            backgroundColor: {
                                xs: '#EEEFF3',
                                md: 'transparent'
                            }
                        }} gap={1}>
                        <TextField
                            className="flex-1"
                            placeholder={t('inputPlaceholder')}
                            multiline
                            maxRows={isMd ? 3 : 10}
                            minRows={isMd ? 1 : 3}
                            sx={{
                                backgroundColor: '#fff',

                            }}
                            value={content}
                            required

                            onChange={(e) => setContent(e.target.value.trim())}
                        />
                        <Button variant="contained" type="submit" disabled={!content || replyLoading} sx={{ backgroundColor: '#333333', whiteSpace: 'nowrap', color: '#fff', paddingTop: 0, pb: 0, width: { xs: 'initial', md: 100 } }}>
                            {replyLoading ? <CircularProgress size={24} color="inherit" /> : t('submit')}
                        </Button>
                    </Box>
                </Container>
            </Box>
        </Layout >
    )
}

export default About