import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import theme from './theme';
import App from './App';
import 'virtual:uno.css'  // UnoCSS
import './i18n'; // 导入i18n配置文件
import { SnackbarProvider } from './contexts/SnackbarContext';
import ErrorBoundary from './components/ErrorBoundary'; // 引入错误边界组件
import './styles/main.css' // 引入全局样式

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </ErrorBoundary>
  // </React.StrictMode>
  ,
);
