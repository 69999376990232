import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group";

import Index from './views/Index';
import About from './views/About';
import Guide from './views/Guide';
import Study from './views/Study';
import Course from './views/Course';
import Customer from './views/Customer';
import GuideDetail from './views/guide/Detail'
import "./styles/transition.css"; // 这里放置动画的 CSS 样式
import React, { useEffect, useRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Tools from './layout/Tools';
import KeepAlive from 'react-activation' //引入
import { AliveScope } from 'react-activation'//引入，需要结合使用
import { HelmetProvider } from 'react-helmet-async';


const AnimatedRoutes = () => {
    const location = useLocation();
    const { pathname } = useLocation();
    const nodeRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    // 判断是否 /customer
    const isCustomer = location.pathname === '/customer'
    return (
        <React.Fragment>
            {isMd && isCustomer ? null : <Header />}
            {/* <TransitionGroup> */}
            <SwitchTransition>
                <CSSTransition key={location.key} nodeRef={nodeRef} classNames="fade" timeout={300}>
                    <HelmetProvider>
                        <Routes location={location}>
                            <Route path="/" element={<KeepAlive cacheKey="Index">
                                <Index />
                            </KeepAlive>
                            } />
                            {/* <Route path="/guide" element={<KeepAlive cacheKey="Guide">
                            <Guide />
                        </KeepAlive>
                        } /> */}
                            <Route path="/guide" element={
                                <Guide />
                            } />
                            <Route path="/about" element={<KeepAlive cacheKey="About">
                                <About />
                            </KeepAlive>
                            } />
                            <Route path="/study" element={<KeepAlive cacheKey="Study">
                                <Study />
                            </KeepAlive>
                            } />
                            <Route path="/course" element={<KeepAlive cacheKey="Course">
                                <Course />
                            </KeepAlive>
                            } />
                            <Route path='/customer' element={<KeepAlive cacheKey="Customer">
                                <Customer />
                            </KeepAlive>
                            } />
                            <Route path='/guide/detail/:id' element={<GuideDetail />} />
                        </Routes>
                    </HelmetProvider>
                </CSSTransition>
            </SwitchTransition>
            {/* </TransitionGroup> */}
            {isMd && isCustomer ? null : <Footer />}
            {isCustomer ? null : <Tools />}
        </React.Fragment>
    );
};
const RouterMain = () => {
    return (
        <Router>
            <AliveScope>
                <AnimatedRoutes />
            </AliveScope>
        </Router>
    );
}

export default RouterMain