import { Button, Container, Grid, Box, Skeleton, useMediaQuery } from "@mui/material"
import Layout from "../../layout/Layout"

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import React, { useEffect } from "react";
import { useGetKnowledgeDetail } from "../../api";
import { useLocation } from 'react-router-dom';
import theme from "../../theme";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import eventBus from "../../plugins/eventBus";

const GuideDetail: React.FC = () => {

    const { response: content, loading, fetchKnowledgeDetail } = useGetKnowledgeDetail();
    const id = useLocation().pathname.split('/').pop();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const { t } = useTranslation();

    useEffect(() => {
        const handleLanguageChange = (data: string) => {
            fetchKnowledgeDetail({ id })

        }

        eventBus.on('languageChange', handleLanguageChange);
        fetchKnowledgeDetail({ id })
        return () => {
            eventBus.off('languageChange', handleLanguageChange);
        }
    }, [])
    return (
        <Layout>
            <Helmet>
                <title>{content?.tdkTitle}</title>
                <meta name="description" content={content?.tdkDes} />
                <meta name="keywords" content={content?.tdkKey} />
            </Helmet>
            <Container className="pt-5 mb-15">
                <Grid container spacing={6}>
                    <Grid sx={{ display: { xs: 'none', md: 'block' } }} md={3} item>
                        <Button className="flex items-center" onClick={() => history.back()}>
                            <KeyboardArrowLeftIcon sx={{ fontSize: '30px', mr: 0.5 }} />
                            <span className="font-size-18px text-#333">
                                {t('back')}
                            </span>
                        </Button>
                    </Grid>
                    <Grid md={9} item>
                        <div className='mb-6 guide'>
                            {loading ?
                                <Box>
                                    <Skeleton animation="wave" height={50} width={'100%'} sx={{ mb: 2 }} />
                                    {Array.from(Array(10)).map((_, index) => (
                                        <Skeleton animation="wave" width={(index % 3 === 0 ? Math.round(Math.random() * 100) : 100) + '%'} key={index} />
                                    ))}
                                </Box>
                                :
                                <React.Fragment>
                                    <div className='font-size-6 font-600 mb-6'>{content?.title}</div>
                                    <div className="mb-6" dangerouslySetInnerHTML={{ __html: isMd ? content?.h5Detail : content?.pcDetail }}>
                                    </div>
                                    {/* <div className="text-center text-#A8A8A8">
                                        暂无更多~
                                    </div> */}
                                </React.Fragment>
                            }

                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default GuideDetail
