
import React, { ReactNode } from 'react';

interface LayoutProps {
    children: ReactNode;
    className?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, className }) => {

    return <React.Fragment>
        <div id="Container" className={className}>
            {children}
        </div>
    </React.Fragment>
};

export default Layout;