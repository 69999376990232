import React, { useEffect, useState } from 'react';

interface IconProps {
  name: string;
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = ({ name, size = 24, color = 'currentColor', className, onClick }) => {
  const [SvgIcon, setSvgIcon] = useState<React.FC<React.SVGProps<SVGSVGElement>> | null>(null);

  useEffect(() => {
    const importSvgIcon = async () => {
      try {
        const icon = await import(`../icons/${name}.svg?react`);
        setSvgIcon(() => icon.default);
      } catch (error) {
        console.error(`Error loading SVG icon: ${name}`, error);
      }
    };

    importSvgIcon();
  }, [name]);

  if (!SvgIcon) {
    return null; // 或者返回一个默认的图标或占位符
  }

  return (
    <SvgIcon width={size} height={size} fill={color} className={className} onClick={onClick} />
  );
};

export default Icon;