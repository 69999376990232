import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';


// Augment the palette to include an white color
declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
    text333: Palette['primary'];
  }

  interface PaletteOptions {
    white?: PaletteOptions['primary'];
    text333?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an white option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    text333: true;
  }
}
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#50AC70',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    white: {
      main: '#FFFFFF',
    },
    text333: {
      main: '#333333',
      dark: '#333333',
      contrastText: '#FFFFFF',
    }
  },
});

export default theme;
