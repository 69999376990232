import { alpha, Box, Button, CircularProgress, Collapse, Container, Drawer, Grid, MenuItem, MenuList, Skeleton, styled, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material"
import Layout from "../layout/Layout"
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { SimpleTreeView, TreeItem, TreeItem2Checkbox, TreeItem2Content, TreeItem2GroupTransition, TreeItem2Icon, TreeItem2IconContainer, TreeItem2Label, TreeItem2Provider, TreeItem2Root, treeItemClasses, UseTreeItem2Parameters } from "@mui/x-tree-view";
import clsx from 'clsx';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useRef, useState } from "react";
import { TreeItem2DragAndDropOverlay } from "@mui/x-tree-view/TreeItem2DragAndDropOverlay";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import SvgIcon from '../components/SvgIcon';

import { Link } from 'react-router-dom';
import { useGetKnowledgeList, useGetKnowledgeTree } from "../api";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import eventBus from "../plugins/eventBus";

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    color: '#333',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.25, 1),
    margin: theme.spacing(0.2, 0),
    [`& [class*='label']`]: {
        fontSize: '18px',
    },
    [`& [class*='iconContainer']`]: {
        color: 'rgba(204, 204, 204, 1)',
        padding: theme.spacing(0, 1.2),
        [`& svg`]: {
            fontSize: '26px',
        }
    },
    [`[class*='groupTransition']`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px solid rgba(224, 224, 224, 1)`,
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
        color: '#50AC70',
        [`& [class*='label']`]: {
            fontWeight: '600',
        }
    }
}));


interface CustomTreeItemProps
    extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    React.HTMLAttributes<HTMLLIElement> {
    icon: string;
    level: number;
}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
    props: CustomTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const { id, itemId, label, disabled, children, icon, level, onClick, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getCheckboxProps,
        getLabelProps,
        getGroupTransitionProps,
        getDragAndDropOverlayProps,
        status,

    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
    // 如果是一级节点，阻止选择行为
    const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {

    };
    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <CustomTreeItemContent {...getContentProps({
                    className: clsx('content', {
                        'Mui-expanded': status.expanded,
                        'Mui-selected': status.selected,
                        'Mui-focused': status.focused,
                        'Mui-disabled': status.disabled,
                    }),
                })}
                >
                    <img className="size-6 object-cover" src={icon} />
                    <TreeItem2Label {...getLabelProps({
                        style: {
                            fontSize: level === 2 ? '14px' : '18px',
                            wordWrap: 'break-word'
                        }
                    })} />
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                </CustomTreeItemContent>
                <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
                {children && <TreeItem2GroupTransition {...getGroupTransitionProps({
                    sx: {
                        marginLeft: '15px',
                        borderLeft: `1px solid rgba(224, 224, 224, 1)`
                    }
                })} />}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});


function ShowMoreExample({ item, index, total }: { item: any, index: number, total: number }) { // 注意参数使用解构传递
    const [expanded, setExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null); // 类型声明
    const { t } = useTranslation();

    // 保证 `useTheme` 和 `useMediaQuery` 的调用顺序在组件中是唯一的
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    // 控制展开/收起
    const handleToggle = () => {
        getShowButton() && setExpanded(!expanded);
    };

    // 计算是否需要显示“显示更多”按钮
    useEffect(() => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            const lineHeight = parseFloat(getComputedStyle(contentRef.current).lineHeight);
            const maxVisibleHeight = lineHeight * (item.isCover ? 4 : 6); // 三行的高度
            setShowButton(item.isImage ? true : contentHeight > maxVisibleHeight);
            console.log(showButton, contentHeight, maxVisibleHeight)
        }
    }, [contentRef.current]); // 添加 `contentRef.current` 作为依赖



    function getShowButton() {
        if (showButton || (item.coverType === 'image' && !item.isText)) {
            if (!(item.coverType === 'video' && !item.isText && !item.isImage) && !(isMd && item.isImage && !item.isText)) {
                return true
            }
        }
        return false
    }
    return (
        <React.Fragment>
            <Box className="mt-6  guide">
                {/* 渲染封面图片 */}
                {item.coverType === 'image' && !expanded && (
                    <Box
                        onClick={handleToggle}
                        component="img"
                        src={item.cover}
                        sx={{
                            width: { xs: 'initial', md: '192px' },
                            height: { xs: 'initial', md: '108px' },
                            objectFit: 'cover',
                            mr: { xs: 0, md: 3 },
                            float: { xs: 'initial', md: (item.coverType !== 'image' && item.isText || (item.isImage && item.isText)) ? 'left' : 'initial' },
                        }}
                    />
                )}
                {/* 视频封面 */}
                {item.coverType === 'video' && !expanded && item.isText && (
                    <Box
                        onClick={handleToggle}
                        component="div"
                        sx={{
                            width: { xs: 'initial', md: '192px' },
                            height: { xs: 'initial', md: '108px' },
                            mr: { xs: 0, md: 3 },
                            float: { xs: 'initial', md: 'left', position: 'relative' },
                        }}
                    >
                        <Box component="video" controls={isMd} src={item.cover} poster={item.cover} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        {!isMd && <div className="bg-black opacity-40 absolute w-full h-full top-0"></div>}
                        {!isMd && (
                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                <SvgIcon size={50} name="video-play" />
                            </div>
                        )}
                    </Box>
                )}

                {/* 渲染内容 */}
                {(item.coverType !== 'image' && item.isText) || (item.coverType === 'video' && !item.isText && !item.isImage) || (item.isImage && item.isText) || expanded ? <Typography
                    onClick={handleToggle}
                    ref={contentRef}
                    variant="body1"
                    component="div"
                    sx={{
                        position: 'relative',
                        display: '-webkit-box',
                        // WebkitLineClamp: (expanded || (item.isEmptyText && item.coverType === 'video')) ? 'unset' : item.cover ? 4 : 6, // 展开时显示完整内容，否则只显示三行
                        // WebkitBoxOrient: 'vertical',
                        overflow: showButton ? 'hidden' : 'initial',
                        maskImage: (expanded || !showButton) ? 'none' : 'linear-gradient(rgba(0, 0, 0, 1) 0, transparent 100%)',
                        textOverflow: 'ellipsis',
                        height: (showButton && !(item.coverType === 'video' && !item.isText && !item.isImage)) ? expanded ? 'initial' : item.isCover ? 100 : 160 : 'initial',
                    }}
                >
                    <div className="html" dangerouslySetInnerHTML={{ __html: isMd ? item.h5Detail : item.pcDetail }}></div>
                </Typography> : null}
            </Box>
            {/* 显示“展开”或“收起”按钮 */}
            {getShowButton() &&
                <div className="text-center mt-4">
                    <Button variant="text" sx={{ color: '#56B8FE', fontSize: '16px' }} onClick={handleToggle}>
                        {expanded ? t('collapseText') : t('readMore')}
                        {expanded ? <KeyboardArrowUpIcon sx={{ color: '#ccc' }} /> : <KeyboardArrowDownIcon sx={{ color: '#ccc' }} />}
                    </Button>
                </div>
            }
            {index < total - 1 && <div className="bg-#ECECEC h-1px my-8"></div>}
        </React.Fragment>
    );
}

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}

        variant="scrollable"
        scrollButtons={false}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    borderBottom: '1px solid #ECECEC',

    '& .MuiTabs-indicator': {
        justifyContent: 'center',
        backgroundColor: 'transparent',
        display: 'none',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#635ee7',
    },
});

interface StyledTabProps {
    label: string;
    onClick: () => void;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '14px',
    marginRight: theme.spacing(1),
    borderRadius: '100px',
    padding: '8px 12px',
    backgroundColor: '#EEEFF3',
    color: '#A8A8A8',
    minHeight: 'auto',
    '&.Mui-selected': {
        color: '#fff',
        backgroundColor: '#50AC70'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));


// 自定义无数据组件
interface EmptyProps {
    description?: string; // 显示的提示文字
    children?: React.ReactNode; // 可选的子组件（例如按钮）
    icon?: React.ReactNode; // 可自定义图标
    t: (key: string) => string
}
const Empty: React.FC<EmptyProps> = ({ description, children, icon, t }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{ height: 200, color: 'text.secondary' }}
        >
            {/* 图标区域 */}
            {icon ? icon : <AutoStoriesIcon sx={{ fontSize: 100, mb: 2, color: '#ccc' }} />}

            {/* 描述文本 */}
            <Typography variant="body1" sx={{ mb: 2 }}>
                {description || t('noData')}
            </Typography>

            {/* 插槽区域，可以传递按钮等其他组件 */}
            {children && <Box>{children}</Box>}
        </Box>
    );
}

function parseHtmlContent(html: string) {
    // 创建一个 DOM 容器来解析 HTML 字符串
    const container = document.createElement('div');
    container.innerHTML = html;

    // 查找图片和视频标签
    const images = container.querySelectorAll('img');
    const videos = container.querySelectorAll('video');

    // 初始化返回结果
    let result = {
        isVideo: false,
        isImage: false,
        isText: false,
        isCover: false,
        coverType: '',
        cover: ''
    }

    // 如果有图片
    if (images.length > 0) {
        result.isImage = true
    }
    if (videos.length > 0) {
        result.isVideo = true
    }
    if (images.length > 0 || videos.length > 0) {
        result.isCover = true
    }
    const content = container.innerText.replaceAll('\n', '').replaceAll('\r', '').replaceAll('\t', '').trim()
    // console.log('内容', content, !!content)
    result.isText = !!content


    // 仅有图片
    if (images.length > 0) {
        result.coverType = 'image'
        result.cover = images[0].getAttribute('src') || ''
    }

    // 仅有视频
    if (videos.length > 0) {
        result.coverType = 'video'
        result.cover = videos[0].getAttribute('poster') || videos[0].getAttribute('src') || ''
    }
    return result;
}
const Guide: React.FC = () => {

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const { response, loading, fetchKnowledgeTree } = useGetKnowledgeTree();
    const { fetchKnowledgeList } = useGetKnowledgeList();
    const isFirstLoad = useRef(true)
    const listLoading = useRef(true)
    const listNoMore = useRef(false)

    // 加载态设置
    const listParams = {
        classifyId: '',
        pageNo: 1,
        pageSize: 10
    }

    response?.result.forEach((v: { children: any[]; }) => {
        v.children = v.children?.sort((a: any, b: any) => {
            return b.sort - a.sort
        })
    })
    const treeData = response?.result.sort((a: any, b: any) => {

        return b.sort - a.sort
    }) || [];

    const [value, setValue] = React.useState(0);

    const [open, setOpen] = React.useState(false);
    const [defaultExpanded, setDefaultExpanded] = React.useState<string[]>([]);
    const [defaultSelected, setDefaultSelected] = React.useState<string>('');
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    const { t } = useTranslation()

    const listData = useRef([]);
    let pages = 0

    function getListData(isInit = false) {
        console.log('加载列表')
        listLoading.current = true;
        if (isInit) {
            listParams.pageNo = 1
            listData.current = []
            isFirstLoad.current = true
        } else {
        }
        fetchKnowledgeList({
            ...listParams,
        }).then((res: { records: { map: (arg0: (item: any) => any) => never[]; }; pages: number; }) => {
            const list = res.records.map((item: any) => {
                const newItem = {
                    ...item,
                    ...parseHtmlContent(isMd ? item.h5Detail : item.pcDetail)
                }
                return newItem
            }) || [];
            if (isInit) {
                listData.current = list
            } else {
                listData.current.push(...list)
            }
            listNoMore.current = listParams.pageNo === pages
            listParams.pageNo++
            pages = res.pages
            console.log('listNoMore', listNoMore)
        }).finally(() => {
            listLoading.current = false
            isFirstLoad.current = false

            console.log('listLoading', listLoading)
        })
    }
    useEffect(() => {
        fetchKnowledgeTree()
        listParams.classifyId = defaultExpanded[0]
        getListData()
        const handleLanguageChange = (data: string) => {
            fetchKnowledgeTree()
            listParams.classifyId = defaultExpanded[0]
            getListData(true)
        };

        // 组件挂载时，监听自定义事件
        eventBus.on('languageChange', handleLanguageChange);

        window.onscroll = function () {
            let scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop
            let windowHeight =
                document.documentElement.clientHeight || document.body.clientHeight
            let scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight
            const bottom = scrollTop + windowHeight
            console.log(bottom, scrollHeight)
            if ((bottom + 500) >= scrollHeight) {
                console.log('到底了', listParams.pageNo, pages, listLoading.current)
                if (!listLoading.current) {
                    if (listParams.pageNo <= pages) {
                        getListData()
                    }
                }
            }
        }

        // 组件卸载时，移除监听器
        return () => {
            eventBus.off('languageChange', handleLanguageChange);
            window.onscroll = null
        };
    }, [])

    function getList(classifyId: string) {
        // setDefaultSelected(classifyId);
        listParams.classifyId = classifyId
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })
        getListData(true)
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setOpen(!!treeData[newValue].child.length);
        getList(response.result[newValue].id)
    };
    return (
        <Layout>
            {isMd ? <Container className="pt-5 !pr-0">
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs"
                >
                    {
                        treeData.map((item: any) => <StyledTab onClick={() => setOpen(!!item.child.length)} label={item.name} key={item.id} />)
                    }
                </StyledTabs>
            </Container> : null}

            <Container className="pt-5">
                <Grid container spacing={6}>
                    {isMd ? null :
                        <Grid md={3} item>
                            {
                                loading ?
                                    Array.from(Array(5)).map((_, index) =>
                                        <React.Fragment key={index}>
                                            <div className="flex items-center mb-4">

                                                <Skeleton variant="rounded" width={24} height={24} />
                                                <Skeleton sx={{ ml: 1 }} key={index} height={25} width={30 + Math.random() * 40 + '%'} />
                                            </div>
                                        </React.Fragment>
                                    )

                                    :
                                    <SimpleTreeView
                                        slots={{
                                            expandIcon: KeyboardArrowDownIcon,
                                            collapseIcon: KeyboardArrowUpIcon,
                                        }}
                                        sx={{ position: 'sticky', top: '145px' }}
                                        defaultExpandedItems={defaultExpanded}
                                        defaultSelectedItems={defaultSelected}
                                        onItemClick={(_event: any, itemId: string) => {
                                            getList(itemId)
                                        }}
                                    >
                                        {treeData.map((item: any) => <CustomTreeItem itemId={item.id} icon={item.icon} level={item.level} label={item.name} key={item.id}>
                                            {item.child.map((item: any) => <CustomTreeItem itemId={item.id} icon={item.icon} level={item.level} label={item.name} key={item.id} />)}
                                        </CustomTreeItem>)}
                                    </SimpleTreeView>
                            }
                        </Grid>
                    }
                    <Grid xs={12} md={9} item>
                        {
                            (listLoading.current && isFirstLoad.current) ? <React.Fragment>{
                                Array.from(Array(7)).map((_, index) => <React.Fragment key={index}>
                                    <Skeleton variant="text" sx={{ fontSize: '2rem', mb: 2 }} width={50 + Math.random() * 30 + '%'} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="100%" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="70%" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="90%" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="100%" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 3 }} width="40%" />
                                </React.Fragment>)}
                            </React.Fragment>
                                :
                                listData.current.length > 0 ? listData.current.map((item: any, index: number) =>
                                    <div className='mb-6' key={item.id}>
                                        <Link to={`/guide/detail/${item.id}`} className='font-size-6 font-600 text-#333 decoration-none !hover:underline'>{item.title}</Link>
                                        <ShowMoreExample key={item.id} item={item} index={index} total={listData.current.length} />
                                    </div>
                                )
                                    : Empty({ t })
                        }
                        {listData.current.length > 0 && <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                            {!listNoMore.current && <CircularProgress />}
                        </Box>}
                    </Grid>
                </Grid>
            </Container>

            {/* 菜单弹出 */}
            <Drawer open={open} elevation={0} sx={{
                mt: '124px',
                '& .MuiDrawer-paper': {
                    width: '70vw',
                    mt: '124px',
                    backgroundColor: ' rgba(255,255,255,0.9)',
                    backdropFilter: 'blur(6px)',

                },
                '& .MuiModal-backdrop': {
                    backgroundColor: 'transparent',
                    mt: '124px',
                }
            }} onClose={toggleDrawer(false)}>
                <MenuList>
                    {treeData[value]?.child.map((item: any) => <MenuItem sx={{ display: 'flex', alignItems: 'center' }} key={item.id} onClick={() => { toggleDrawer(false)(); getList(item.id) }}>
                        <img className="size-6 object-cover" src={item.icon} />
                        <span className="font-size-14px ml-2">
                            {item.name}
                        </span>
                    </MenuItem>)}
                </MenuList>
            </Drawer>
        </Layout>
    )
}

export default Guide
